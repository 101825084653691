<template>
  <b-overlay :show="isUpdating" rounded>
    <div class="border rounded mb-1">
      <b-row v-if="listBenefit != 0" class="p-1 border-bottom">
        <b-col md="3">
          <validation-provider name="Hotel" rules="required">
            <b-form-group slot-scope="{ errors }" label="Seleccione hotel*">
              <b-form-select
                :state="errors.length > 0 ? false : null"
                v-model="bookingInfo.resortId"
                :disabled="isLoadingRooms || bookingInfo.isEdit == 'true'"
                @input="setHotel"
              >
                <option value="">Seleccione hotel</option>
                <option
                  v-for="hotel in filteredHotels"
                  :key="hotel.id"
                  :value="hotel.id"
                >
                  {{ hotel.name }}
                </option>
              </b-form-select>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="3" v-if="!checkOutItsOver">
          <b-form-group label="Check in - Check out*">
            <flat-pickr
              v-model="bookingInfo.rangeDate"
              :disabled="isLoadingRooms || !bookingEditable.valid"
              class="form-control"
              :config="dateCheckConfig"
              @input="setNights"
            />
          </b-form-group>
          <!-- <span>Noches: {{bookingInfo.nights}} </span>-->
        </b-col>
        <b-col md="1">
          <b-form-group label="Noches">
            <b-form-input v-model="bookingInfo.nights" disabled />
          </b-form-group>
        </b-col>
        <b-col md="1">
          <b-form-group>
            <validation-provider name="Adultos" rules="required">
              <b-form-group slot-scope="{ errors }" label="Adultos*">
                <b-form-select
                  v-model="bookingInfo.adults"
                  :state="errors.length > 0 ? false : null"
                  :disabled="isLoadingRooms || !bookingEditable.valid"
                  @change="changeNumberAdults(bookingInfo)"
                >
                  <option
                    v-for="(adulto, index) in adultOptions"
                    :key="index"
                    :value="adulto.value"
                  >
                    {{ adulto.title }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback>{{
                  errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="1">
          <b-form-group label="Niños*">
            <validation-provider
              #default="{ errors }"
              name="Niños"
              rules="required"
            >
              <b-form-select
                v-model="bookingInfo.numberChildren"
                :options="childrenOptions"
                :state="errors.length > 0 ? false : null"
                :disabled="isLoadingRooms || !bookingEditable.valid"
                @change="changeNumberChildren"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group label="Tipo de ocupacion">
            <validation-provider
              #default="{ errors }"
              name="tipo de ocupacion"
            >
              <b-form-select
                v-model="bookingInfo.occupanttype"
                :state="errors.length > 0 ? false : null"
                :disabled="isLoadingRooms || !bookingEditable.valid"
                @change="changeOccupation"
              >
                <option
                  v-for="(item, index) in occupanttype"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- <b-col md="6">
          <b-form-group label="Notas Pre-arrival">
            <b-form-textarea
              @change="isChangeCommets"
              v-model="bookingInfo.internalnotes"
              placeholder="Escribe la nota"
              rows="2"
            >
            </b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col v-if="!bookingInfo.ishistory" md="4">
          <b-form-group label="Notas del propietario">
            <b-form-textarea
              @change="isChangeCommets"
              v-model="bookingInfo.notes"
              rows="2"
            >
            </b-form-textarea>
          </b-form-group>
        </b-col> -->
      </b-row>

      <b-row v-if="listBenefit != 0" class="mt-2 p-1">
        <b-col
          md="6"
          class="mb-2 border-right"
          v-if="bookingInfo.infoAdults != null"
        >
          <h3>Adultos</h3>

          <div v-for="(adult, index) in bookingInfo.infoAdults" :key="index">
            <!-- <label>Adulto {{ index + 1 }}*</label> -->
            <b-row>
              <b-col>
                <validation-provider :name="'nombre adulto '+ (parseInt(index) + 1)" rules="required">
                  <b-form-group slot-scope="{valid, errors }" >
                    <label :for="'AgeAdult' + index"> Nombre* </label>
                    <b-form-input
                      v-model="adult.name"
                      :state="errors[0] ? false : valid ? true : null"
                    />
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider :name="'apellido adulto '+ (parseInt(index) + 1)" rules="required">
                  <b-form-group slot-scope="{valid, errors }" >
                    <label :for="'AgeAdult' + index"> Apellido* </label>
                    <b-form-input
                      v-model="adult.lastname"
                      :state="errors[0] ? false : valid ? true : null"
                    />
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider :name="'teléfono adulto '+ (parseInt(index) + 1)" rules="required">
                  <b-form-group slot-scope="{valid, errors }" >
                    <label :for="'AgeAdult' + index"> Teléfono*</label>
                    <b-form-input
                      v-model="adult.phone"
                      :state="errors[0] ? false : valid ? true : null"
                    />
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider :name="'email adulto '+ (parseInt(index) + 1)" :rules="adult.ismain || index === 0 ? 'required|email' : ''">
                  <b-form-group slot-scope="{valid, errors }" >
                    <label :for="'AgeAdult' + index"> Email* </label>
                    <b-form-input
                      v-model="adult.email"
                      required
                      :state="errors[0] ? false : valid ? true : null"
                    />
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <center>
                <b-col class="align-center" v-if="index !== 0">
                  <b-input-group-append>
                    <b-button
                      variant="danger"
                      size="sm"
                      class="mt-2 align-center"
                      @click="deleteAgeAdults(adult.key)"
                      :disabled="isLoadingRooms"
                    >
                      <b-icon icon="trash-fill"></b-icon>
                    </b-button>
                  </b-input-group-append>
                </b-col>
              </center>
            </b-row>
          </div>
          <hr />
        </b-col>
        <b-col
          md="6"
          class="mb-2 border-left"
          v-if="bookingInfo.ninos.length > 0"
        >
          <h3>Niños</h3>
          <div v-for="(child, index) in bookingInfo.ninos" :key="index">
            <b-row>
              <b-col>
                <validation-provider :name="'edad niño ' + (parseInt(index) + 1)" rules="">
                  <b-form-group slot-scope="{valid, errors }">
                    <label :for="'AgeChild' + index"> Edad* </label>
                      <b-form-select
                        :id="'AgeChild' + index"
                        v-model="child.edad"
                        :disabled="isLoadingRooms"
                        @change="setAgeNinio(child)"
                      >
                        <option
                          v-for="(edad, index) in ageChildrenOptions"
                          :key="index" :value="edad.value"
                          :state="errors[0] ? false : valid ? true : null"
                        > {{ edad.text }}
                        </option>
                      </b-form-select>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider :name="'nombre niño ' + (parseInt(index) + 1)" rules="">
                  <b-form-group slot-scope="{valid, errors }" >
                    <label :for="'AgeChild' + index"> Nombre* </label>
                    <b-form-input
                      v-model="child.name"
                      :state="errors[0] ? false : valid ? true : null"
                    />
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider :name="'apellido niño ' + (parseInt(index) + 1)" rules="">
                  <b-form-group slot-scope="{valid, errors }" >
                    <label :for="'AgeChild' + index"> Apellido* </label>
                    <b-form-input
                      v-model="child.lastname"
                      :state="errors[0] ? false : valid ? true : null"
                    />
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <b-input-group-append>
                  <b-button
                    variant="danger"
                    size="sm"
                    class="mt-2"
                    @click="deleteAgeChild(child.key)"
                    :disabled="isLoadingRooms"
                  >
                    <b-icon icon="trash-fill"></b-icon>
                  </b-button>
                </b-input-group-append>
              </b-col>
            </b-row>
          </div>
          <hr />
        </b-col>
        <b-col cols="12" class="mt-1">
          <b-button
            variant="warning"
            @click.prevent="resetForm"
            :disabled="isLoadingRooms"
            v-if="bookingInfo.isEdit != 'true'"
            >Limpiar</b-button
          >

          <div class="float-right" v-if="bookingInfo.status != 4 ">
            <b-button
              v-if="bookingInfo.status != 3 && btnSearchOrUpdate.showSearch"
              variant="primary"
              type="submit"
              :disabled="disableSubmit"
              @click.prevent="getBookings(bookingInfo)"
            >
              <!-- @click.prevent="getBookings"-->
              <b-spinner small label="Loading..." variant="info" v-if="isLoadingRooms"/>
              Buscar
            </b-button>
            <b-button
              v-if="(bookingEditable.current || (!checkInItsOver && !btnSearchOrUpdate.showSearch)) && can('fivesclub_owner_booking_show_booking_reservation_list_action_button_update')"
              @click.prevent="bookingModify(bookingInfo)"
              variant="primary"
            > <b-spinner small v-if="isUpdating" /> Actualizar 
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { acl } from "@/modules/auth/mixins/acl"
import Ripple from 'vue-ripple-directive'
import { showAlertMessage } from "@/helpers/helpers"

export default {
  directives: { Ripple },
  mixins: [acl],
  props: {
    isUpdating: {
      type: Boolean,
      required: true,
    },
    bookingInfo: {
      type: Object,
      required: true,
    },
    checkOutItsOver: {
      type: Boolean,
      required: true,
    },
    checkInItsOver: {
      type: Boolean,
      required: true,
    },
    filteredHotels: {
      type: Array,
      required: true,
    },
    adultOptions: {
      type: Array,
      required: true,
    },
    occupanttype: {
      type: Array,
      required: true,
    },
    listBenefit: {
      type: Number,
      required: true,
    },
    isLoadingRooms: {
      type: Boolean,
      required: true,
    },
    btnSearchOrUpdate: {
      type: Object,
      required: true,
    },
    dateCheckConfig: {
      type: Object,
      required: true,
    },
    ageChildrenOptions: {
      type: Array,
      required: true,
    },
    childrenOptions: {
      type: Array,
      required: true,
    },
    isAlreadySelected: {
      type: Boolean,
      required: true,
    },
    bookingEditable: {
      type: Object,
      required: true,
    }
  },
  components: {
    flatPickr,
  },
  computed: {
    disableSubmit(){
      return !this.bookingInfo?.infoAdults[0]?.email || !this.bookingInfo?.infoAdults[0]?.name || this.isLoadingRooms || (!this.bookingInfo?.rangeDate || this.bookingInfo?.rangeDate == 'undefined to undefined')
    }
  },
  methods: {
    changeNumberAdults(booking){
      this.$emit('change-number-adults', booking)
    },
    changeNumberChildren(numberChildren){
      this.$emit('change-number-children', numberChildren)
    },
    changeOccupation(){
      if( this.bookingInfo.occupanttype !== '2' && this.bookingInfo.occupantTypeOriginal === '2'){
        showAlertMessage("Beneficios de Reserva", "InfoIcon", `Si tiene beneficios de reserva seleccionados estos serán eliminados.`, "warning", 4000, "bottom-right" )
      }
      this.$emit('change-occupation')
    },
    deleteAgeAdults(adult){
      this.$emit('delete-age-adults', adult)
    },
    deleteAgeChild(child){
      this.$emit('delete-age-child', child)
    },
    setAgeNinio(child){
      this.$emit('set-age-ninio', child)
    },
    resetForm(){
      this.$emit('reset-form')
    },
    getBookings(booking){		
      this.$emit('get-bookings', booking)
    },
    bookingModify(booking){		
		booking.idBooking = parseInt(booking.id) || null // si es edición este vendra con valor	
      	this.$emit('booking-modify', booking)
    },
    setNights(rangeDate){
      this.$emit('set-nights', rangeDate)
    },
    setHotel(){
      this.$emit('set-hotel')
    },
    isChangeCommets(){
      this.$emit('change-commets')
    },
  }
}
</script>