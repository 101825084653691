<template>
    <div>              
        <b-overlay :show="isUpdating"> 
            <div v-if="benefitsToUseInBooking.length > 0">
                <b-row>                    
                    <b-col md="12" class="ml-1">
                        <span class="mr-2" v-if="statusBenefits.statusName" >
                            <b>Status:</b>
                             {{ statusBenefits.statusName }}
                        </span>
                        <span v-if="statusBenefits.nameAuthorize" >
                            <b>Autorizó:</b>
                             {{ statusBenefits.nameAuthorize }}
                        </span>
                    </b-col>
                </b-row>
      

                <div class="container-tables">
                    <div class="column-table" v-if="benefitsFirstGroup.length > 0">
                        <h6 class="subtitle font-weight-bolder mb-1">Año: {{  yearsBenefitsList[0] }}</h6>
                        
                        <div class="table-responsive">
                            <table class="table table-sm">
                                <thead>
                                    <tr>                                
                                        <th> Beneficio </th>												                                        
                                        <th class="centerT"> Balance </th>
                                        <th class="centerT"> Cantidad a usar </th>
                                    </tr>
                                </thead>
                                
                                <tbody>																                             
                                    <tr v-for="(benefit, index) in benefitsFirstGroup" :key="index">                            
                                        <td >
                                            {{benefit.benefitname}}
                                        </td>
                                                            
                                        <td class="centered-cell">
                                            {{ benefit.balance }}
                                        </td>
                                        <td class="centered-cell">
                                            <b-form-input  
                                                v-if="canEditBenefitByPermission"                                      
                                                size="sm"
                                                type="number"
                                                placeholder="Cantidad"	
                                                :max="benefit.balance"
                                                style="max-width: 150px;"
                                                v-model="benefit.qty" 
                                                @input="setQtyBenefit(benefit)"   
                                                :disabled="!canEditBeforeCheckOut"                                                               
                                            />	                                    
                                            <span v-else>
                                                {{benefit.qty}}                                        
                                            </span> 	                                     
                                        </td>
                                    </tr>								
                                </tbody>										
                            </table>	
                            
                        </div>
                    </div>
                    <div class="column-table" v-if="benefitsSecondGroup.length > 0">
                        <h6 class="subtitle font-weight-bolder mb-1">Año: {{  yearsBenefitsList[1] }}</h6>            
                        <div class="table-responsive">
                            <table class="table table-sm">
                                <thead>
                                    <tr>                                
                                        <th> Beneficio </th>	
                                        <th class="centerT"> Balance </th>
                                        <th class="centerT">Cantidad a usar </th>
                                    </tr>
                                </thead>
                                
                                <tbody>																                             
                                    <tr v-for="(benefit, index) in benefitsSecondGroup" :key="index">                            
                                        <td >
                                            {{benefit.benefitname}}
                                        </td>                                                        
                                        <td class="centered-cell">
                                            {{ benefit.balance }}
                                        </td>
                                        <td class="centered-cell">
                                            <b-form-input  
                                                v-if="canEditBenefitByPermission"                                      
                                                size="sm"
                                                type="number"
                                                style="max-width: 150px;"
                                                placeholder="Cantidad"	
                                                :max="benefit.balance"
                                                v-model="benefit.qty" 
                                                @input="setQtyBenefit(benefit)"   
                                                :disabled="!canEditBeforeCheckOut"                                                               
                                            />	                                    
                                            <span v-else>
                                                {{benefit.qty}}                                        
                                            </span>          
                                        </td>
                                    </tr>								
                                </tbody>										
                            </table>	
                            
                        </div>                        
                    </div>
                </div>
           
            
                <div class="d-flex justify-content-end" >
                    <b-button 
                        v-if="canEditBenefitByPermission"          
                        @click.prevent="updateBenefits"
                        variant="primary"  
                        :disabled="!canEditBeforeCheckOut || isUpdating "              
                    > 
                        Actualizar
                    </b-button>
                </div>
            </div>
            <div v-else>                
                <b-alert show variant="warning">
                    <div class="alert-body text-center">
                        <span>No se tiene beneficios a usar en la reserva </span>                    
                    </div>
                </b-alert>
            </div>
        </b-overlay>
        
    </div>
</template>

<script>    
    import { mapActions, mapState , mapMutations, mapGetters } from 'vuex'  
    import { showAlertMessage } from "@/helpers/helpers"

    import { acl } from "@/modules/auth/mixins/acl"
import { status } from '@/data/data';
    export default {
	    mixins: [acl],
        props: {			            
            booking: {
                type: Object,
                default: null,
            },
        
        },
        data() {
            return {
                isUpdating: false,
                badgeStatus:{
                    1: 'warning', 
                    2: 'primary',
                    3: 'danger'
                },
                statuses:{
                    1: "Pendiente Autorización", 2: "Autorizado", 3:  "No Autorizado"
                }
            }
        },
        computed: {		
            ...mapState('fivesClubCatalogs', ['yearsBenefitsList','benefitsToUseInBooking']),	                   	        
            ...mapGetters('fivesClubCatalogs', ['benefitsFirstGroup', 'benefitsSecondGroup']),                 
            ...mapState('auth', ['user']),
            canEditBeforeCheckOut(){
                const currentDate = new Date().toISOString().split('T')[0]
                const givenDate = new Date(this.booking.dateout).toISOString().split('T')[0]
                return currentDate < givenDate
            },
            canEditBenefitByPermission(){   
                const hasBenefitsToEdit = this.benefitsToUseInBooking.length > 0                        
                return hasBenefitsToEdit && this.can('fivesclub_owner_booking_update_benefits_booking')
            },
            statusBenefits(){                          
                const firstValidBenefit = this.benefitsToUseInBooking.find(item => item.id !== null && item.status )   
                if( firstValidBenefit ){
                    const status = firstValidBenefit.status
                    const nameAuthorize = firstValidBenefit.nameAuthorize
                    return { statusName: this.statuses[status] || statuses[1], valueStatus: status, nameAuthorize }
                }                 
                return { statusName: '', valueStatus: 1, nameAuthorize: ''}
            }
        },
        methods:{
            ...mapActions('fivesClubCatalogs',['updateBenefitsBookingOwner']),
            ...mapMutations('fivesClubCatalogs',['setQtyBenefitToUseInBooking']),		           
            setQtyBenefit( data ){
                const { transaction, qty } = data
                this.setQtyBenefitToUseInBooking({transaction, qty })
            },
            async updateBenefits(){
                if( this.booking.occupantTypeOriginal !== '2' ){
                    showAlertMessage( `Reserva para Invitados`, "BellIcon", `Los beneficios aplican para reservas de Invitados`, "warning", 3000, "bottom-right" )
                    return
                }                
                const count = this.benefitsToUseInBooking.filter(item => parseFloat(item.qty) > parseFloat(item.balance)).length               
                if( count > 0 ){                   
				    this.$swal.fire({ icon: "warning", title: 'Beneficios', text:'Tienes beneficios seleccionados que exceden su balance' })
                    return
                }
                const benefits = this.payloadBenefits()
                this.isUpdating = true
                const response = await this.updateBenefitsBookingOwner( {benefits} )
                if( response.status ){
                    showAlertMessage( `Ok`, "BellIcon", `Beneficios actualizados correctamente`, "success", 3000, "bottom-right" )
                    this.isUpdating = false                    
                    this.$emit('load-info')
                }                
                this.isUpdating = false                    
            },
            payloadBenefits(){
                const benefits = this.benefitsToUseInBooking                    
                    .map(item => {
                        return {	
                            id: item.id || null,
					        ...( !item.id ? { createdBy: this.user.idUser } : {}),                          					        					        
                            // authorizedBy: item.id ? item.authorizedBy : null,                       
                            authorizedBy:  null,                       
                            // status: item.id ? item.status : 1,                       
                            status:  1,                       
                            balance: item.balance,
                            benefit: item.benefit,                                          
                            ownerbooking: this.booking.id,
                            owner: this.booking.owner,
                            transaction: item.transaction,
                            qty: item.qty,					
                        }
                    }).filter(item => item.qty > 0 || item.id !== null)
                return benefits
            },
        }
    }
</script>

<style scoped>
   .centered-cell, th.centerT {
        text-align: center; /* Centrado horizontal */
        vertical-align: middle; /* Centrado vertical */
    }

    td input {
        margin: 0 auto;
        display: block;
    }

    .container-tables {
        display: flex;
        flex-direction: column;
        gap: 1rem; 
        padding: 1rem;
    }

    .column-table{
        max-width: 700px;
        width: 100%;        
    }

   
    @media(min-width: 950px) {
        .container-tables {
            flex-direction: row;
            gap: 7rem; 
        }
        .column-table{
            flex: 1;
        }
    }
</style>